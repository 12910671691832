import * as api from '../../utils/api.util';
import * as commonActions from '../actions/common.action';

export function getCashJournalReport(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      dispatch(commonActions.setLoading({ show: true }));
      const { from, to } = payload;
      const res = await api.REPORT_POST({ from, to, unit_id }, '/kas');
      return res.formatted;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getBankJournalReport(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      dispatch(commonActions.setLoading({ show: true }));

      const res = await api.REPORT_POST({
        ...payload, unit_id,
      }, '/bank');
      return res.formatted;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getDailyJournalReport(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      dispatch(commonActions.setLoading({ show: true }));
      const { from, to } = payload;
      const res = await api.REPORT_POST({ from, to, unit_id }, '/harian');
      return res.formatted;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getBalanceReport(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.REPORT_POST(payload, '/balance');
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getGeneralLedgerReport(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.REPORT_POST(payload, '/general-ledger');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getMonthlyReport(payload = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.REPORT_POST({ ...payload, unit_id }, '/monthly');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}


export function getProfitLossReport(payload = {}) {
  return async (dispatch) => {
    try {
      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.REPORT_POST(payload, '/profit-loss');
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getBudgetRealization(filters = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { periode = null } = filters;
      const { workingUnit, prm_school_units_id } = user;
      let unit_id = prm_school_units_id;

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.id;
      }

      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.REPORT_POST({ periode, unit_id }, '/budget-realization');
      return res.data;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPaymentReport(filters = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { year, va_code } = filters;
      const { workingUnit, school_unit = {} } = user;
      let unit_id = [] 
      if (school_unit && school_unit.va_code) {
      	unit_id = school_unit.va_code;
      }
      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.attributes.va_code;
      }

      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.PAYMENT_REPORT({
        year, unit_id, va_code, organizations_id: 3,
      });
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getSupervisionReport(filters = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { year, coa } = filters;
      const { workingUnit, school_unit = {} } = user;
      const va_code =( workingUnit && workingUnit.id) ? workingUnit.attributes.va_code : school_unit.va_code || '';

      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.SUPERVISION_REPORT({
        year, va_code, coa, organizations_id: 3,
      });
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}

export function getPaymentRecap(filters = {}) {
  return async (dispatch, getState) => {
    try {
      const storeState = getState();
      const { user } = storeState;
      const { workingUnit, school_unit = {} } = user;
      let unit_id = school_unit ? school_unit.va_code : [];

      if (workingUnit && workingUnit.id) {
        unit_id = workingUnit.attributes.va_code;
      }

      dispatch(commonActions.setLoading({ show: true }));
      const res = await api.PAYMENT_RECAP({
        ...filters, unit_id, organizations_id: 3,
      });
      return res;
    } catch (err) {
      throw err;
    } finally {
      dispatch(commonActions.setLoading({ show: false }));
    }
  };
}
